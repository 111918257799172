/* eslint react/display-name: 0 */
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { useTrail } from "react-spring";
import styled from "styled-components";
import { Layout, ProjectItem } from "../components";

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20vw, 1fr));
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoints.m}),
    (max-device-width: ${props => props.theme.breakpoints.m}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${props => props.theme.breakpoints.s}),
    (max-device-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Index = ({
  data: {
    allMdx: { edges: projectEdges }
  },
  location
}) => {
  const trail = useTrail(projectEdges.length, {
    from: { height: "0%" },
    to: { height: "100%" }
  });

  return (
    <Layout pathname={location.pathname}>
      <ListWrapper>
        {trail.map((style, index) => (
          <ProjectItem
            testid={`projectItem-${index}`}
            style={style}
            key={projectEdges[index].node.fields.slug}
            node={projectEdges[index].node}
          />
        ))}
      </ListWrapper>
    </Layout>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired
    })
  }).isRequired,
  location: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query IndexQuery {
    allMdx(
      sort: { fields: [frontmatter___order], order: DESC }
      filter: { fields: { sourceInstanceName: { eq: "projects" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            order
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 850
                  quality: 90
                  traceSVG: { color: "#f3f3f3" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
